@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@-webkit-keyframes bounce-y {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.sec-title h2{
  font-size: 3.5rem !important;
}

@keyframes bounce-y {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes bounce-x {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes bounce-x {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

/***
====================================================================
  Anim Icons
====================================================================

***/

.anim-icons {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

.anim-icons.full-width {
  max-width: 100%;
}

.anim-icons .icon {
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.icon-circle-1 {
  width: 315px;
  height: 315px;
  // background-image: url(../images/icons/circle-1.png);
  -webkit-animation: fa-spin 30s infinite;
  animation: fa-spin 30s infinite;
}

.icon-circle-2 {
  width: 300px;
  height: 300px;
  background-image: url(../circle-2.png);
  -webkit-animation: fa-spin 30s infinite;
  animation: fa-spin 30s infinite;
}

.icon-circle-2 {
  left: -120px;
  top: 100px;
}

.main-header::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #00082e;
  content: "";
}

.main-header {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 99;


  .header-tops {
    position: relative;

    .container {
      position: static;
      max-width: 1200px;
      padding: 0px 15px;
      margin: 0 auto;
      width: 100%;

      .inner-container {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
      }
    }

    .top-left {
      position: relative;
      padding-left: 30px;
      min-width: 240px;

      .social-icon-one {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        li {
          position: relative;
          margin-right: 10px;

          a {
            position: relative;
            display: block;
            font-size: 18px;
            color: #ffffff;
            height: 40px;
            width: 40px;
            line-height: 40px;
            text-align: center;
            background-color: #00082e;
            border-radius: 0 0 50% 50%;
            -webkit-transition: all 500ms ease;
            -o-transition: all 500ms ease;
            transition: all 500ms ease;

            :hover {
              background-color: #c4d627;
              color: #ffffff;
            }
          }
        }
      }
    }

    .top-right {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 15px 0;
      width: 100%;
      padding-left: 70px;

      .contact-list-one {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;

        li {
          position: relative;
          padding-left: 55px;
          font-size: 14px;
          line-height: 25px;
          color: #ffffff;
          font-weight: 400;
          margin-right: 60px;

          i {
            position: absolute;
            left: 0;
            top: 7px;
            font-size: 38px;
            line-height: 30px;
            color: #c4d627;
          }
        }
      }
    }
  }
}

/* Header Lower */

.main-header .header-lower {
  position: relative;
  margin-bottom: -30px;
}

.main-header .main-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0 0 0 40px;
}

.main-header .main-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 10000%;
  background-color: #ffffff;
  content: "";
}

.main-header .nav-outer {
  position: static;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.main-header .logo-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 240px;
  min-height: 110px;
  background-color: #ffffff;
  border-radius: 10px 10px 0 40px;
  margin-top: -30px;
  margin-right: 70px;
}

.main-header .logo img {
  height: 100px;
}

.main-header .logo {
  position: relative;
  z-index: 9;
  padding: 15px 15px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-header .menu-box {
  position: static;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.main-menu {
  position: static;
}

.main-menu .navbar-header {
  display: none;
}

.main-menu .navbar-collapse {
  padding: 0px;
}

.main-menu .navigation {
  position: static;
  margin: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-menu .navigation>li {
  position: relative;
  margin-right: 48px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation>li>a {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  padding: 20px 0;
  color: #081245;
  opacity: 1;
  text-align: center;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation>li.current>a,
.main-menu .navigation>li>a:hover {
  color: #c4d627;
}

.main-menu .navigation>li>a:before {
  position: absolute;
  left: 50%;
  bottom: 20px;
  height: 2px;
  width: 0%;
  content: "";
  -webkit-transform: scale(-1);
  -ms-transform: scale(-1);
  transform: scale(-1);
  background-color: #c4d627;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation>li.dropdown>a:after {
  content: "\f107";
  position: absolute;
  right: -14px;
  top: 50%;
  width: 10px;
  height: 20px;
  display: block;
  line-height: 20px;
  font-size: 12px;
  z-index: 5;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.main-menu .navigation>li.current>a:before,
.main-menu .navigation>li:hover>a:before {
  left: 0;
  width: 100%;
}

.main-menu .navigation>li>ul>li.dropdown>a:before {
  font-family: "Font Awesome 5 Free";
  content: "\f105";
  position: absolute;
  right: 25px;
  top: 8px;
  width: 10px;
  height: 20px;
  display: block;
  line-height: 20px;
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  z-index: 5;
}

.main-menu .navigation>li>ul>li>ul>li.dropdown:hover>a:after {
  color: #ffffff;
}

.main-menu .navigation>li>ul {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 220px;
  z-index: 100;
  display: none;
  opacity: 0;
  visibility: hidden;
  padding: 20px 20px;
  background: #ffffff;
  border-radius: 3px;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  transform: rotateX(90deg);
  border-bottom: 3px solid #c4d627;
  border-radius: 0 0 6px 6px;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation>li>ul:before {
  position: absolute;
  content: "";
  left: 0px;
  top: -30px;
  width: 100%;
  height: 30px;
  display: block;
}

.main-menu .navigation>li>ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation>li>ul>li {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
}

.main-menu .navigation>li>ul>li:last-child {
  margin-bottom: 0px;
}

.main-menu .navigation>li>ul>li>a {
  position: relative;
  display: block;
  padding: 5px 5px;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #222222;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li:hover>a {
  color: #1b0a3f;
}

.main-menu .navigation>li>ul>li>ul {
  position: absolute;
  left: 100%;
  top: 100%;
  width: 220px;
  z-index: 100;
  display: none;
  opacity: 0;
  visibility: hidden;
  margin-top: -50px;
  padding: 20px 0px;
  background: #ffffff;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  transform: rotateX(90deg);
  border-bottom: 3px solid #c4d627;
  border-radius: 0 0 6px 6px;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation>li>ul>li>ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation>li>ul>li>ul>li {
  position: relative;
  width: 100%;
  margin-bottom: 0px;
}

.main-menu .navigation>li>ul>li>ul>li:last-child {
  margin-bottom: 0px;
}

.main-menu .navigation>li>ul>li>ul>li>a {
  position: relative;
  display: block;
  padding: 5px 25px;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #222222;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li>ul>li:hover>a {
  color: #1b0a3f;
}

.main-menu .navigation>li.dropdown:hover>ul {
  visibility: visible;
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu .navigation li>ul>li.dropdown:hover>ul {
  visibility: visible;
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu .navbar-collapse>ul li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #ffffff;
  line-height: 28px;
  border: 1px solid #ffffff;
  background-size: 20px;
  cursor: pointer;
  z-index: 5;
  display: none;
}

.main-header .outer-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}


/* Search btn */

.main-header .search-box-btn {
  position: relative;
  height: 40px;
  width: 40px;
  text-align: center;
  background: #c4d627;
  border-radius: 50%;
  line-height: 40px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.main-header .theme-btn {
  margin-left: 30px;
  background-color: #c4d627;
  white-space: nowrap;
  display: block;
  border-radius: 25px;
}

.right-side-anim {
  right: 0;
  top: 0;
  max-width: 450px;
  width: 100%;
  transition: right .4s cubic-bezier(.05, .74, .2, .99);
  border-left: 1px solid rgba(34, 41, 47, .05);
  background: #fff;
  position: fixed !important;
  z-index: 1000;
}

/***
  Hidden Sidebar style

***/

.hidden-bar {
  position: fixed;
  right: 0;
  top: 0px;
  height: 100%;
  overflow-y: auto;
  z-index: 99999;
  opacity: 0;
  max-width: 320px;
  width: 100%;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  visibility: hidden;
  background: -o-linear-gradient(45deg, #000b46 0%, #001166 100%);
  background: linear-gradient(45deg, #000b46 0%, #001166 100%);
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.hidden-bar::-webkit-scrollbar {
  width: 2px;
}

.hidden-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px #cecece;
  box-shadow: inset 0 0 2px #cecece;
  border-radius: 10px;
}

.hidden-bar::-webkit-scrollbar-thumb {
  background: #7a7a7a;
  border-radius: 10px;
}

.hidden-bar::-webkit-scrollbar-thumb:hover {
  background: #7a7a7a;
}

.form-back-drop {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .50);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.hidden-bar {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.form-back-drop {
  opacity: 1;
  visibility: visible;
}

.hidden-bar .inner-box {
  position: relative;
  padding: 30px 30px 50px;
  width: 100%;
}

.hidden-bar .title-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}

.hidden-bar .title-box .cross-icon {
  position: relative;
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
}

.hidden-bar .inner-box h2 {
  position: relative;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2em;
  color: #ffffff;
}

.hidden-bar .inner-box p {
  color: #ffffff;
  margin-bottom: 25px;
}


/*=== Form Style One ===*/

.form-style-one {
  position: relative;
}

.form-style-one .form-group {
  position: relative;
  margin-bottom: 20px;
}

.form-style-one .form-group:last-child {
  margin-bottom: 0;
}

.form-style-one .form-group input::placeholder,
.form-style-one .form-group textarea::placeholder,
.form-style-one .form-group select::placeholder {
  color: #fff !important;
}

.form-style-one .form-group input,
.form-style-one .form-group textarea,
.form-style-one .form-group select {
  position: relative;
  width: 100%;
  display: block;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 10px;
  height: 40px;
  background-color: rgba(255, 255, 255, .10);
  border-bottom: 1px solid rgba(255, 255, 255, .30);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.form-style-one .form-group input:focus,
.form-style-one .form-group select:focus,
.form-style-one .form-group textarea:focus {
  border-color: #1370b5;
}

.form-style-one .form-group textarea {
  height: 150px;
  resize: none;
  padding-top: 15px;
}

.form-style-one .form-group input[type="submit"],
.form-style-one .form-group button {
  position: relative;
  display: inline-block;
  width: 100%;
}

.hidden-bar .contact-list-one {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-top: 40px;
}

.hidden-bar .contact-list-one li {
  margin-bottom: 20px;
  padding-left: 50px;
  margin-right: 0;
}

.hidden-bar .contact-list-one li i {
  font-size: 28px;
}

.hidden-bar .contact-list-one li strong {
  font-size: 16px;
  line-height: 20px;
}

.btn-style-one {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px 30px;
  font-weight: 600;
  background: #001376;
  overflow: hidden;
  min-width: 180px;
}

.contact-list-one {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.contact-list-one li {
  position: relative;
  padding-left: 55px;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  font-weight: 400;
  margin-right: 60px;
}

.contact-list-one li:last-child {
  margin-right: 0;
}

.contact-list-one li strong {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
  text-transform: uppercase;
}

.contact-list-one li i {
  position: absolute;
  left: 0;
  top: 7px;
  font-size: 38px;
  line-height: 30px;
  color: #c4d627;
}

.contact-list-one li a {
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.time-countdown {
  display: flex;
}
.nav-toggler {
  position: relative;
  color: #c4d627;
  font-size: 34px;
  margin-left: auto;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}

.nav-toggler:hover {
  color: #c4d627;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

/*** 

====================================================================
  Mobile Header
====================================================================

***/
.mobile-menu.true {
  opacity: 1 !important;
  visibility: visible !important;
}

.menu-box.true {
  opacity: 1 !important;
  visibility: visible !important;
  transform: none !important;
}

.mobile-header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: none;
  background: #ffffff;
  z-index: 99;
  padding: 15px 20px;
}

.mobile-header .logo {
  position: relative;
  padding: 0;
}

.mobile-header .logo img {
  max-height: 40px;
}

.mobile-header .nav-outer {
  position: relative;
  width: auto;
}

.mobile-header .outer-box {
  position: relative;
}

.sticky-header .mobile-search-btn,
.mobile-header .mobile-search-btn {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: #081245;
  top: 5px;
}

.mobile-header .search-box {
  margin: 0;
}

.mobile-header .cart-btn {
  font-size: 20px;
  height: 40px;
  width: 40px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.mobile-header .cart-btn {
  margin-left: 10px;
}


/*** 

====================================================================
    Mobile Menu
====================================================================

***/

.mobile-nav-toggler {
  position: relative;
  font-size: 26px;
  line-height: 20px;
  cursor: pointer;
  color: #081245;
  display: none;
  top: 0px;
  margin-left: 20px;
}

.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;

  .active {
    color: #e1137b !important;
  }
}

.mobile-menu .upper-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px;
}

.mobile-menu .nav-logo {
  position: relative;
  text-align: left;
  width: 100%;
}

.mobile-menu .nav-logo img {
  max-height: 40px;
}

.mobile-menu-visible {
  overflow: hidden;
}

.mobile-menu-visible .mobile-menu {
  opacity: 1;
  visibility: visible;
}

.mobile-menu-visible .scroll-to-top {
  opacity: 0;
  visibility: hidden;
}

.mobile-menu .menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.9);
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
}

.mobile-menu-visible .mobile-menu .menu-backdrop {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu .menu-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: #ffffff;
  padding: 0px 0px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
}

.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.7s ease 500ms;
  -o-transition: all 0.7s ease 500ms;
  transition: all 0.7s ease 500ms;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu .close-btn {
  position: relative;
  text-align: center;
  font-size: 18px;
  color: #111111;
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  // -webkit-transform: translateY(-50px);
  // -ms-transform: translateY(-50px);
  // transform: translateY(-50px);
  margin-right: 10px;
}

.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.mobile-menu .close-btn:hover {
  opacity: 0.5;
}

.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.10);
}

.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}

.mobile-menu .navigation li>ul>li:last-child {
  border-bottom: none;
}

.mobile-menu .navigation li>ul>li:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.10);
}

.mobile-menu .navigation li>a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 20px;
  font-size: 16px;
  color: #404040;
  font-weight: 500;
  text-transform: capitalize;
}

.mobile-menu .navigation li:hover>a,
.mobile-menu .navigation li.current>a {
  color: #e1137b;
}

.mobile-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 44px;
  height: 44px;
  text-align: center;
  font-size: 16px;
  line-height: 44px;
  color: #404040;
  cursor: pointer;
  z-index: 5;
  display: none;
}

.mobile-menu .navigation li.dropdown .dropdown-btn:after {
  content: '';
  position: absolute;
  left: 0px;
  top: 10px;
  width: 1px;
  height: 24px;
  border-left: 1px solid rgba(0, 0, 0, 0.10);
}

.mobile-menu .navigation li>ul,
.mobile-menu .navigation li>ul>li>ul {
  display: none;
}

.mobile-menu .navigation li>ul>li {
  padding-left: 20px;
}

.mobile-menu .contact-list-one {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
  padding: 50px 20px 20px;
}

.mobile-menu .contact-list-one li {
  margin-bottom: 20px;
  padding-left: 45px;
  color: #333333;
  margin-right: 0;
}

.mobile-menu .contact-list-one li a {
  color: #333333;
}

.mobile-menu .contact-list-one li i {
  font-size: 28px;
}

.mobile-menu .contact-list-one li strong {
  font-size: 14px;
  line-height: 18px;
}

.mobile-menu .social-links {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #ffffff;
  width: 100%;
  border-top: 1px solid #dddddd;
  margin-top: auto;
}

.mobile-menu .social-links li {
  position: relative;
  text-align: center;
  width: 100%;
  border-right: 1px solid #dddddd;
}

.mobile-menu .social-links li a {
  position: relative;
  display: block;
  line-height: 50px;
  height: 50px;
  font-size: 14px;
  text-align: center;
  color: #081245;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.mobile-menu .social-links li a:hover {
  color: #ff7500;
}

.sticky-header .outer-box,
.sticky-header .navbar-header {
  display: none;
}

/* Cart btn */

.main-header .cart-btn {
  position: relative;
  display: block;
  z-index: 1;
  color: #ffffff;
  font-size: 24px;
  line-height: 40px;
}

.main-header .cart-btn .count {
  position: absolute;
  right: -7px;
  top: -4px;
  height: 18px;
  width: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
  color: #ffffff;
  background-color: #1370b5;
  border-radius: 50%;
}



/************************ Mega Menu ***************************/

.main-menu .navigation>li.has-mega-menu {
  position: static;
}

.main-menu .navigation>li>.mega-menu {
  position: absolute;
  left: 0px;
  right: 0;
  margin: auto;
  width: 100%;
  max-width: 1170px;
  background: #ffffff;
  padding: 20px;
  top: 100%;
  z-index: 100;
  opacity: 0;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}


.main-menu .navigation>li:hover>.mega-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
}

.main-menu .navigation>li>.mega-menu .mega-menu-bar {
  position: relative;
}

.main-menu .navigation>li .mega-menu-bar h3 {
  position: relative;
  color: #00082e;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.main-menu .navigation>li .mega-menu-bar>ul {
  position: relative;

}

.main-menu .navigation>li .mega-menu-bar .column>ul>li {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #dddddd;
  text-transform: capitalize;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation>li .mega-menu-bar .column {
  position: relative;
  margin-bottom: 10px;
}

.main-menu .navigation>li .mega-menu-bar .column>ul>li:last-child {
  border: none;
}

.main-menu .navigation>li .mega-menu-bar .column>ul>li>a {
  position: relative;
  display: block;
  padding: 10px 20px;
  line-height: 24px;
  font-weight: 400;
  color: #000000;
  text-align: left;
  font-size: 14px;
  text-transform: capitalize;
  transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -webkit-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
}

.main-menu .navigation>li .mega-menu-bar .column>ul>li>a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  height: 1px;
  text-align: left;
  margin: 0;
  opacity: 0;
}

.main-menu .navigation>li .mega-menu-bar .column>ul>li>a:hover {
  z-index: 1;
  color: #c4d627;
}

.main-menu .navigation>li .mega-menu-bar .column>ul>li>a:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}


/*** 

====================================================================
Search Popup
====================================================================

***/

.search-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  margin-top: -540px;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  background-color: #00082e;
  z-index: 99999;
  visibility: hidden;
  -webkit-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
  -o-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
  -webkit-transition: all 1500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
  -o-transition: all 1500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
  transition: all 1500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
  /* easeInOutQuint */
  -webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
  -o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
  transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
  /* easeInOutQuint */
}

.sidenav-bar-visible .search-popup {
  width: 80%;
}

.search-popup:before {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 560px;
  // background-image: url(../images/icons/wave.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: -20px;
  content: "";
}

.search-active .search-popup {
  visibility: visible;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  margin-top: 0;
}

.search-popup .close-search {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #ffffff;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 0;
  visibility: hidden;
}

.search-popup .close-search span {
  position: relative;
  display: block;
  height: 70px;
  width: 70px;
  font-size: 30px;
  line-height: 70px;
  color: #ffffff;
}

.search-active .search-popup .close-search {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 1500ms;
  -o-transition-delay: 1500ms;
  transition-delay: 1500ms;
}

.search-popup form {
  position: absolute;
  max-width: 700px;
  top: 50%;
  left: 15px;
  right: 15px;
  margin: -35px auto 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.search-active .search-popup form {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-delay: 1200ms;
  -o-transition-delay: 1200ms;
  transition-delay: 1200ms;
}

.search-popup .form-group {
  position: relative;
  margin: 0px;
  overflow: hidden;
}

.search-popup .form-group input[type="text"],
.search-popup .form-group input[type="search"] {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  height: 50px;
  width: 100%;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, .10);
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.40);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.40);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-popup .form-group input[type="submit"],
.search-popup .form-group button {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background: transparent;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  padding: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-popup .form-group input[type="submit"]:hover,
.search-popup .form-group button:hover {
  color: #ffbc00;
}

.search-popup input::-webkit-input-placeholder,
.search-popup textarea::-webkit-input-placeholder {
  color: #aaaaaa;
}

.search-popup input::-moz-placeholder,
.search-popup textarea::-moz-placeholder {
  color: #aaaaaa;
}

.search-popup input:-ms-input-placeholder,
.search-popup textarea:-ms-input-placeholder {
  color: #aaaaaa;
}

.search-popup input::-ms-input-placeholder,
.search-popup textarea::-ms-input-placeholder {
  color: #aaaaaa;
}

.search-popup input::placeholder,
.search-popup textarea::placeholder {
  color: #aaaaaa;
}


.about-section {
  position: relative;
  padding: 90px 0 40px;
}

.about-section .content-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section .content-column .inner-column {
  position: relative;
  padding-top: 60px;
  padding-left: 5px;
}

.about-section .content-column p {
  margin-bottom: 24px;
}

.about-section .content-column .btn-box {
  position: relative;
  margin-top: 40px;
}

.about-section .image-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section .about-image-wrapper {
  position: relative;
  padding-top: 150px;
  max-width: 570px;
  margin: 0 auto;
  width: 100%;
}

.about-section .image-1 {
  position: relative;
  margin-left: -180px;
  overflow: hidden;
  margin-bottom: 0;
  z-index: -1;
}

.about-section .image-1 img {
  -webkit-animation: bounce-x 10s infinite;
  animation: bounce-x 10s infinite;
}

.about-section .image-2 {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 30px solid #c4d627;
  overflow: hidden;
}
.sec-title h2{
  font-weight: 600;
}

.about-section .image-3 {
  position: absolute;
  border: 30px solid #001376;
  top: 0;
  left: -50px;
  z-index: 2;
  border-radius: 50%;
  overflow: hidden;
}

.about-section .about-image-wrapper .play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 3;
  width: 140px;
  height: 140px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #ffffff;
  margin-left: -20px;
  border-radius: 50%;
}

.about-section .about-image-wrapper .play-btn:before {
  position: absolute;
  height: 110px;
  width: 110px;
  border: 2px dashed #001376;
  border-radius: 50%;
  left: 16px;
  top: 16px;
  content: "";
  -webkit-animation: zoomInOut 2s infinite;
  animation: zoomInOut 2s infinite;
}

.about-section .about-image-wrapper .play-btn .icon {
  position: relative;
  height: 75px;
  width: 75px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #001376;
  padding-left: 5px;
  color: #ffffff;
  font-size: 22px;
  border-radius: 50%;
  content: "";
}

@-webkit-keyframes zoomInOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(.9);
    transform: scale(.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes zoomInOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(.9);
    transform: scale(.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


/***

==================================================================
  About Section Two
==================================================================

***/

.about-section-two {
  position: relative;
  padding: 100px 0;
}

.about-section-two .icon-circle-3 {
  left: -855px;
  top: 320px;
  -webkit-animation: fa-spin 120s infinite linear;
  animation: fa-spin 120s infinite linear;
}

.about-section-two .icon-circle-4 {
  top: 810px;
  left: -340px;
}

.about-section-two .content-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section-two .content-column .part-title {
  margin-bottom: 0;
}

.about-section-two .part-title .text {
  font-size: 18px;
  line-height: 1.6em;
}

.part-title .sub-title {
  display: block;
  font-size: 16px;
  color: #fe00c5;
  font-weight: 700;
  font-family: 'Exo', sans-serif;
  margin-bottom: 15px;
}

.part-title h2 {
  display: block;
  font-size: 36px;
  line-height: 48px;
  color: #081245;
  font-weight: 700;
}

.part-title .text {
  position: relative;
  margin-top: 30px;
}

.part-title .divider {
  position: relative;
  display: block;
  width: 68px;
  height: 5px;
  background-image: url(../divider.png);
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 30px;
}

.sec-title.text-center .divider {
  margin: 30px auto 0;
  width: 90px;
  height: 5px;
  background-image: url(../divider.png);
}

.date-box-outer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.date-box-outer:before {
  position: absolute;
  left: 70px;
  top: 30px;
  height: 230px;
  width: 230px;
  // background-image: url(../images/icons/pattern-1.png);
  content: "";
}

.date-box-one {
  position: relative;
  padding: 30px;
  text-align: center;
  background: #000b46;
  color: #ffffff;
  background: -o-linear-gradient(45deg, #000b46 0%, #001166 100%);
  background: linear-gradient(45deg, #000b46 0%, #001166 100%);
  max-width: 200px;
  height: 100%;
}

.date-box-one .day {
  display: block;
  font-size: 60px;
  line-height: 1em;
  color: #ffffff;
  font-weight: 700;
}

.date-box-one .month {
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-transform: uppercase;
}

.date-box-one .title {
  position: relative;
  display: block;
  font-size: 16px;
  font-family: 'Exo', sans-serif;
  font-weight: 700;
  line-height: 24px;
  padding-top: 15px;
  margin-top: 15px;
}

.date-box-one .title:before {
  position: absolute;
  left: 50%;
  top: 0;
  width: 40px;
  margin-left: -20px;
  height: 2px;
  background: #c4d627;
  content: "";
}

.date-box-one.bg_yellow {
  margin-top: 70px;
  margin-left: -30px;
  background: #ffc600;
  background: -webkit-gradient(linear, left top, right top, color-stop(1%, #ffc600), to(#ff6c00));
  background: -o-linear-gradient(left, #ffc600 1%, #ff6c00 100%);
  background: linear-gradient(90deg, #ffc600 1%, #ff6c00 100%);
}

.date-box-one.bg_yellow .month,
.date-box-one.bg_yellow .day {
  color: #081245;
}

.date-box-one.bg_yellow .title:before {
  background: #000b46;
}

.about-section-two .fact-counter-two {
  margin-left: 100px;
}


/* Fact Counter Two  */

.fact-counter-two {
  position: relative;
  max-width: 770px;
  // background-image: url(../images/icons/counter-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 40px 0 0;
  z-index: 3;
}

.fact-counter-two .counter-column {
  position: relative;
  margin-bottom: 30px;
}

.fact-counter-two .counter-column .inner-box {
  position: relative;
  text-align: center;
}

.fact-counter-two .counter-column .inner-box:before {
  position: absolute;
  right: -15px;
  top: 15px;
  height: 60px;
  width: 1px;
  background: #ffffff;
  content: "";
}

.fact-counter-two .counter-column:last-child .inner-box:before {
  display: none;
}

.fact-counter-two .counter-column .count-text {
  position: relative;
  display: block;
  font-size: 48px;
  line-height: 1.2em;
  color: #ffffff;
  font-weight: 700;
  font-family: "Exo", sans-serif;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.fact-counter-two .counter-column .counter-title {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.about-section-two .video-box-outer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-right: 70px;
  padding-left: 50px;
}

.about-section-two .video-box-outer:before {
  position: absolute;
  left: 0px;
  bottom: 50px;
  height: 350px;
  width: 100%;
  // background-image: url(../images/icons/pattern-2.png);
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
  content: "";
}

.video-box-one {
  position: relative;
  margin-top: -35px;
  margin-left: -90px;
  margin-bottom: 90px;
}

.video-box-one:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: #000934;
  opacity: .50;
  z-index: 1;
}

.video-box-one .image {
  position: relative;
  margin-bottom: 0;
}

.video-box-one .image img {
  display: block;
  width: 100%;
}

.video-box-one .play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 78px;
  line-height: 1em;
  color: #fff;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
}

.video-box-one .play-btn .icon {
  position: relative;
  display: inline-block;
  -webkit-animation: pulse 2s infinite linear;
  animation: pulse 2s infinite linear;
}

.info-box-one {
  position: relative;
  z-index: 3;
}

.info-box-one .inner-box {
  position: relative;
  max-width: 470px;
  background: #001166;
  padding: 40px 50px;
}

.info-box-one h4 {
  font-size: 30px;
  line-height: 36px;
  color: #c4d627;
  font-weight: 700;
  margin-bottom: 20px;
}

.info-box-one p {
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 30px;
}

.speakers-section {
  padding: 90px 0 50px;
  background-color: #f4f7ff;
  overflow: hidden;
}

.speakers-section:before {
  background-image: url(../4.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  -webkit-animation: bounce-x 10s infinite linear;
  animation: bounce-x 10s infinite linear;
}

.speakers-section .icon-dotted-circle {
  left: -560px;
  top: -120px;
  -webkit-animation: fa-spin 60s infinite linear;
  animation: fa-spin 60s infinite linear;
}

.speaker-block {
  position: relative;
  margin-bottom: 40px;
}

.speaker-block .inner-box {
  position: relative;
  max-width: 300px;
  margin: 0 auto;
}

.speaker-block .image-box {
  position: relative;
  padding: 17px 22px 0 0;
  margin: 0 15px;
}

.speaker-block .image {
  position: relative;
  border: 5px solid #ffffff;
  -webkit-box-shadow: 0 14px 20px rgba(0, 0, 0, .20);
  box-shadow: 0 14px 20px rgba(0, 0, 0, .20);
  border-radius: 0 0 250px 250px;
  overflow: hidden;
  margin-bottom: 0;
}

.speaker-block .image-box:before {
  position: absolute;
  left: 40px;
  right: 0;
  top: 0;
  bottom: 17px;
  border: 3px solid #dddddd;
  border-radius: 0 0 250px 250px;
  content: "";
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block .inner-box:hover .image-box:before {
  border: 3px solid #081245;
  border-color: #001376;
  opacity: 1;
}

.speaker-block .image img {
  width: 100%;
  -webkit-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.speaker-block .inner-box:hover .image img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.speaker-block .plus-icon {
  position: absolute;
  right: 25px;
  bottom: 20px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #081245;
  border-radius: 50%;
  background-color: #c4d627;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block .social-links {
  position: absolute;
  right: 30px;
  bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #c4d627;
  border-radius: 50px;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.speaker-block .inner-box:hover .social-links {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}

.speaker-block .social-links a {
  position: relative;
  margin: 5px 0;
  height: 40px;
  width: 40px;
  display: block;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: #c4d627;
  color: #081245;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block .social-links a:hover {
  color: #ffffff;
  background-color: #081245;
}

.speaker-block .info-box {
  position: relative;
  padding-top: 25px;
  display: block !important;
}

.speaker-block h4 {
  display: block;
  font-size: 24px;
  color: #001376;
}

.speaker-block h4 a {
  color: #001376;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block .designation {
  font-size: 14px;
  color: #555555;
}


/***

====================================================================
  Speakers Section Two
====================================================================

***/

.speakers-section-two {
  padding: 90px 0 50px;
  background-color: #081245;
  overflow: hidden;
}

.speakers-section-two .icon-object-10 {
  left: -310px;
  bottom: 100px;
  -webkit-animation: fa-spin 30s infinite linear;
  animation: fa-spin 30s infinite linear;
}

.speakers-section-two .icon-object-15 {
  right: -115px;
  top: 65px;
  -webkit-animation: fa-spin 90s infinite linear;
  animation: fa-spin 90s infinite linear;
}

.speakers-section-two .icon-object-16 {
  display: block;
  left: -290px;
  bottom: 560px;
  -webkit-animation: fa-spin 60s infinite linear;
  animation: fa-spin 60s infinite linear;
}

.speaker-block-two {
  position: relative;
  margin-bottom: 50px;
}

.speaker-block-two .inner-box {
  position: relative;
}

.speaker-block-two .image-box {
  position: relative;
  padding: 20px 20px;
  max-width: 270px;
  margin: 0 auto;
}

.speaker-block-two .image {
  position: relative;
  height: 230px;
  width: 230px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 0;
}

.speaker-block-two .image-box:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 140px;
  width: 140px;
  content: "";
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block-two .image img {
  width: 100%;
  -webkit-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.speaker-block-two .inner-box:hover .image img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.speaker-block-two .plus-icon {
  position: absolute;
  left: 20px;
  bottom: 30px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #081245;
  border-radius: 50%;
  background-color: #ffffff;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block-two .social-links {
  position: absolute;
  left: 25px;
  bottom: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #c4d627;
  border-radius: 50px;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.speaker-block-two .inner-box:hover .social-links {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}

.speaker-block-two .social-links a {
  position: relative;
  margin: 5px 0;
  height: 40px;
  width: 40px;
  display: block;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: #c4d627;
  color: #081245;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block-two .social-links a:first-child,
.speaker-block-two .social-links a:last-child {
  margin: 0;
}

.speaker-block-two .social-links a:hover {
  color: #ffffff;
  background-color: #081245;
}

.speaker-block-two .info-box {
  position: relative;
  padding: 10px 0 30px;
  border-bottom: 1px solid #001373;
  text-align: center;
  max-width: 230px;
  margin: 0 auto;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block-two .inner-box:hover .info-box {
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  padding: 20px 0 20px;
}

.speaker-block-two .info-box:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  content: "";
  background: #001373;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block-two .inner-box:hover .info-box:before {
  height: 100%;
}

.speaker-block-two h4 {
  display: block;
  font-size: 24px;
  color: #ffffff;
}

.speaker-block-two h4 a {
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block-two .designation {
  position: relative;
  font-size: 14px;
  color: #ffffff;
}


/***

====================================================================
  Speakers Section Three
====================================================================

***/

.speakers-section-three {
  padding: 90px 0 50px;
  background-color: #f4f7ff;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.speakers-section-three .icon-dotted-circle {
  left: -560px;
  top: -120px;
  -webkit-animation: fa-spin 60s infinite linear;
  animation: fa-spin 60s infinite linear;
}

.speaker-block-three {
  position: relative;
  margin-bottom: 40px;
  overflow: hidden;
}

.speaker-block-three .inner-box {
  position: relative;
  padding: 20px 25px 25px;
  text-align: center;
}

.speaker-block-three .inner-box:before {
  position: absolute;
  left: 7px;
  top: 0;
  height: 230px;
  width: 230px;
  border: 5px solid #ffffff;
  content: "";
}

.speaker-block-three .inner-box:after {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 230px;
  width: 220px;
  border: 10px solid #247ffb;
  content: "";
}

.speaker-block-three .image-box {
  position: relative;
  padding: 10px;
  z-index: 1;
}

.speaker-block-three .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  z-index: 9;
}

.speaker-block-three .image-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  background: #0019b2;
  background: -o-linear-gradient(45deg, #0019b2 0%, #2d0060 100%);
  background: linear-gradient(45deg, #0019b2 0%, #2d0060 100%);
  z-index: 1;
}

.speaker-block-three .image-box:after {
  position: absolute;
  left: -10px;
  bottom: -10px;
  height: 70px;
  width: 70px;
  background-color: rgba(255, 255, 255, .10);
  content: "";
}

.speaker-block-three .image img {
  width: 100%;
  -webkit-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.speaker-block-three .info-box {
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  padding: 30px;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 3px 16px rgba(0, 0, 0, .10);
  box-shadow: 0 3px 16px rgba(0, 0, 0, .10);
  background-color: rgba(255, 255, 255, .90);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block-three h4 {
  display: block;
  font-size: 24px;
  color: #001376;
  font-weight: 600;
  margin-bottom: 5px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block-three h4 a {
  color: #001376;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block-three .designation {
  font-size: 14px;
  color: #555555;
  margin-bottom: 20px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block-three .social-links {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block-three .social-links a {
  display: block;
  text-align: center;
  line-height: 38px;
  margin: 0 3px 10px;
  height: 40px;
  width: 40px;
  border: 1px solid #1f1b79;
  border-radius: 50%;
  color: #081245;
  font-size: 16px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block-three .social-links a:hover {
  background: #ff7500;
  border-color: #ff7500;
  color: #ffffff;
}

.speaker-block-three .text {
  position: relative;
}


/* Animate 1 */

.speaker-block-three h4,
.speaker-block-three .designation,
.speaker-block-three .info-box,
.speaker-block-three .social-links,
.speaker-block-three .text {
  opacity: 0;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block-three .image-box:hover h4,
.speaker-block-three .image-box:hover .info-box,
.speaker-block-three .image-box:hover .designation,
.speaker-block-three .image-box:hover .social-links,
.speaker-block-three .image-box:hover .text {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}


/* Animate 2 */

.speaker-block-three .image-box:hover h4 {
  -webkit-transition-delay: 200ms;
  -o-transition-delay: 200ms;
  transition-delay: 200ms;
}


/* Animate 3 */

.speaker-block-three .image-box:hover .designation {
  -webkit-transition-delay: 400ms;
  -o-transition-delay: 400ms;
  transition-delay: 400ms;
}


/* Animate 4 */

.speaker-block-three .image-box:hover .social-links {
  -webkit-transition-delay: 600ms;
  -o-transition-delay: 600ms;
  transition-delay: 600ms;
}


/* Animate 5 */

.speaker-block-three .image-box:hover .text {
  -webkit-transition-delay: 800ms;
  -o-transition-delay: 800ms;
  transition-delay: 800ms;
}

.pricing-section {
  position: relative;
  padding: 100px 0 50px;
  background-color: #f2f2f2;
}

.pricing-section .row {
  margin: 0 -25px;
}

.pricing-block {
  position: relative;
  padding: 0 25px;
  margin-bottom: 50px;
}

.pricing-block .inner-box {
  height: 100%;
  position: relative;
  background: #081245;
  background: -o-linear-gradient(45deg, #081245 0%, #001376 100%);
  background: linear-gradient(45deg, #081245 0%, #001376 100%);
  max-width: 370px;
  margin: 0 auto;
  padding: 50px 55px 50px;
  -webkit-box-shadow: 0 6px 28px rgba(0, 0, 0, .13);
  box-shadow: 0 6px 28px rgba(0, 0, 0, .13);
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block .inner-box:before {
  position: absolute;
  right: -29%;
  bottom: -20px;
  height: 200%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  content: "";
  -webkit-transform: rotate(-24deg);
  -ms-transform: rotate(-24deg);
  transform: rotate(-24deg);
}

.pricing-block .inner-box:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #ffffff;
  content: "";
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block .inner-box:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pricing-block .title {
  position: relative;
  font-size: 16px;
  line-height: 36px;
  color: #ffc200;
  font-weight: 700;
  display: block;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 2;
}

.pricing-block h3 {
  font-size: 30px;
  line-height: 1.2em;
  color: #01136d;
  font-weight: 700;
  margin-bottom: 25px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 2;
}

.pricing-block .price {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 72px;
  color: #ff4200;
  font-weight: bold;
  line-height: 1.2;
  font-family: 'Exo', sans-serif;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 2;
}

.pricing-block .price sup {
  font-size: 24px;
  color: #c4d627;
  font-weight: 400;
  top: -20px;
  font-family: 'Archivo', sans-serif;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block .price sub {
  font-size: 16px;
  color: #ff7f1a;
  font-weight: 400;
  margin-left: 5px;
  top: 15px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block .features {
  position: relative;
  padding: 30px 0 25px;
  z-index: 2;
}

.pricing-block .features li {
  position: relative;
  padding-left: 30px;
  font-size: 16px;
  line-height: 26px;
  color: #494949;
  margin-bottom: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block .features li:before {
  position: absolute;
  left: 0;
  top: 10px;
  font-size: 14px;
  color: #494949;
  content: "";
  width: 7px;
  height: 7px;
  background: #c4d627;
  border-radius: 50%;
  font-weight: 900;
  font-family: 'Font Awesome 5 Free';
  transition: all 300ms ease;
}

.pricing-block .theme-btn {
  position: relative;
  z-index: 2;
  border-radius: 25px;
}
.newBtnColor{
  background: #c4d627;
}
.pricing-block .theme-btn:hover {
  color: #fff;
}
.pricing-block.tagged .inner-box:after {
  opacity: 0;
}

.pricing-block .inner-box:hover .features li:before,
.pricing-block .inner-box:hover .features li,
.pricing-block .inner-box:hover .price sup,
.pricing-block .inner-box:hover .price,
.pricing-block .inner-box:hover h3,
.pricing-block.tagged .features li:before,
.pricing-block.tagged .features li,
.pricing-block.tagged .price sup,
.pricing-block.tagged .price,
.pricing-block.tagged h3 {
  color: #ffffff;
}

.pricing-block.tagged .price sub {
  color: #c4d627;
}

.pricing-block .inner-box:hover:after {
  opacity: 0;
}


/***

====================================================================
      Pricing Section Two
====================================================================

***/

.pricing-section-two {
  position: relative;
  padding: 100px 0 50px;
  background-color: #f5f5f5;
}

.pricing-section-two .row {
  margin: 0 -25px;
}

.pricing-block-two {
  position: relative;
  padding: 0 25px;
  margin-bottom: 50px;
}

.pricing-block-two .inner-box {
  position: relative;
  background: #081245;
  background: -o-linear-gradient(45deg, #081245 0%, #001376 100%);
  background: linear-gradient(45deg, #081245 0%, #001376 100%);
  -webkit-box-shadow: 0 6px 28px rgba(0, 0, 0, .13);
  box-shadow: 0 6px 28px rgba(0, 0, 0, .13);
  max-width: 350px;
  margin: 0 auto;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block-two .inner-box:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #ffffff;
  content: "";
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block-two .inner-box:before {
  position: absolute;
  right: -29%;
  bottom: -20px;
  height: 200%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  content: "";
  -webkit-transform: rotate(-24deg);
  -ms-transform: rotate(-24deg);
  transform: rotate(-24deg);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block-two .title-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  padding: 10px 20px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block-two .title-box:before {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
  width: 90%;
  background: #06124f;
  content: "";
  -webkit-transform: skew(-20deg);
  -ms-transform: skew(-20deg);
  transform: skew(-20deg);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block-two .title {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #ffc200;
  font-weight: 700;
  display: block;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block-two h3 {
  font-size: 30px;
  line-height: 1.2em;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block-two .price-box {
  position: relative;
  min-height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 2;
}

.pricing-block-two .price-box:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  // background-image: url(../images/icons/pricing-bg.png);
  background-repeat: no-repeat;
  background-position: center right;
  content: "";
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block-two .price {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-size: 72px;
  color: #ffffff;
  font-weight: bold;
  line-height: 1.2;
  padding: 15px 35px 0;
  font-family: 'Exo', sans-serif;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block-two .price sup {
  position: relative;
  font-size: 24px;
  color: #ffffff;
  font-weight: 400;
  top: -20px;
  font-family: 'Archivo', sans-serif;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block-two .price sub {
  position: relative;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  margin-left: 5px;
  top: 15px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block-two .features {
  position: relative;
  padding: 30px 70px 25px;
  z-index: 2;
}

.pricing-block-two .features li {
  position: relative;
  padding-left: 30px;
  font-size: 16px;
  line-height: 26px;
  color: #494949;
  margin-bottom: 14px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block-two .features li:before {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  color: #494949;
  content: "\f00c";
  font-weight: 900;
  font-family: 'Font Awesome 5 Free';
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block-two .btn-box {
  position: relative;
  padding: 0 70px 50px;
  z-index: 2;
}

.pricing-block-two.tagged .inner-box:after {
  opacity: 0;
}

.pricing-block-two .inner-box:hover .features li:before,
.pricing-block-two .inner-box:hover .features li,
.pricing-block-two .inner-box:hover .price sup,
.pricing-block-two .inner-box:hover .price,
.pricing-block-two .inner-box:hover h3,
.pricing-block-two.tagged .features li:before,
.pricing-block-two.tagged .features li,
.pricing-block-two.tagged .price sup,
.pricing-block-two.tagged .price,
.pricing-block-two.tagged h3 {
  color: #ffffff;
}

.pricing-block-two .inner-box:hover .price sub,
.pricing-block-two.tagged .price sub {
  color: #c4d627;
}

.pricing-block-two .inner-box:hover:after {
  opacity: 0;
}


/***

====================================================================
      Pricing Section Three
====================================================================

***/

.pricing-section-three {
  position: relative;
  padding: 100px 0 50px;
}

.pricing-section-three .icon-object-11 {
  top: 165px;
  left: -505px;
  -webkit-animation: fa-spin 10s infinite linear;
  animation: fa-spin 10s infinite linear;
}

.pricing-section-three .icon-object-12 {
  bottom: 85px;
  left: -35px;
  -webkit-animation: bounce-x 10s infinite linear;
  animation: bounce-x 10s infinite linear;
}

.pricing-section-three .icon-object-13 {
  right: -505px;
  bottom: 130px;
  -webkit-animation: fa-spin 10s infinite linear;
  animation: fa-spin 10s infinite linear;
}

.pricing-section-three .icon-object-14 {
  top: 270px;
  right: -240px;
  -webkit-animation: fa-spin 10s infinite linear;
  animation: fa-spin 10s infinite linear;
}

.pricing-section-three .row {
  margin: 0 -25px;
}

.pricing-block-three {
  position: relative;
  padding: 0 25px;
  margin-bottom: 50px;
}

.pricing-block-three .inner-box {
  position: relative;
  background-color: #f6f6f6;
  padding: 40px 30px 50px;
  border: 1px solid #e1e1e1;
  overflow: hidden;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-block-three .inner-box:hover {
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, .10);
  box-shadow: 0 10px 30px rgba(0, 0, 0, .10);
}

.pricing-block-three .title {
  font-size: 16px;
  line-height: 36px;
  color: #ffc200;
  text-transform: uppercase;
  display: block;
}

.pricing-block-three h3 {
  font-size: 30px;
  line-height: 1.2em;
  color: #01136d;
  font-weight: 700;
}

.pricing-block-three .text {
  font-size: 12px;
  line-height: 24px;
  color: #555555;
  display: block;
  margin-bottom: 20px;
}

.pricing-block-three .price-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pricing-block-three .price {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 72px;
  color: #ff4200;
  font-weight: bold;
  line-height: 1.2;
  font-family: 'Exo', sans-serif;
}

.pricing-block-three .price sup {
  top: -20px;
  text-align: left;
  font-size: 24px;
  color: #c4d627;
  font-weight: 400;
  font-family: 'Archivo', sans-serif;
}

.pricing-block-three .price sup em {
  position: relative;
  display: block;
  top: 20px;
  font-size: 16px;
  color: #ff7f1a;
}

.pricing-block-three .features {
  position: relative;
  padding: 30px 0 0;
}

.pricing-block-three .features li {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
  margin-bottom: 25px;
}

.pricing-block-three .features li.false {
  opacity: .50;
}

.pricing-block-three .features li strong {
  display: block;
  font-size: 24px;
  line-height: 34px;
  color: #01136d;
  font-weight: 700;
  font-family: 'Exo', sans-serif;
}

.pricing-block-three .theme-btn {
  min-width: 100px;
  margin: 0 auto;
}

.pricing-block-three .inner-box:hover,
.pricing-block-three.tagged .inner-box {
  // background-image: url(../images/icons/pricing-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
}

/*** 

====================================================================
    Clients Section
====================================================================

***/

.clients-section {
  position: relative;
  padding: 30px 0 20px;
}

.clients-section.alternate {
  padding: 100px 0 70px;
}

.clients-section.alternate2 {
  padding: 100px 0 20px;
}

.clients-section .sponsors-outer {
  position: relative;
  margin-bottom: 50px;
}

.clients-section .sponsors-outer .owl-dots,
.clients-section .sponsors-outer .owl-nav {
  display: none;
}

.client-block {
  position: relative;
  margin-bottom: 30px;
}

.righ-side-slide {
  text-align: center;
}

.client-block .image-box {
  margin: 0 15px;
  position: relative;
  text-align: center;
  margin-bottom: 0;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
  border: 1px solid transparent;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.client-block .image-box:hover {
  border: 1px solid #dddddd;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.20);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.20);
}

.client-block .image-box img {
  width: auto;
  opacity: 1;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.client-block .image-box:hover img {
  opacity: .70;
}


.posts-block .image-box {
  margin: 0 15px;
  width: 40% !important;
  position: relative;
  text-align: center;
  margin-bottom: 0;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
  border: 1px solid transparent;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.posts-block .image-box:hover {
  border: 1px solid #dddddd;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.20);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.20);
}

.posts-block .image-box img {
  width: auto;
  opacity: 1;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.posts-block .image-box:hover img {
  opacity: .70;
}


/*** 

====================================================================
  Clients Section Two
====================================================================

***/

.clients-section-two {
  position: relative;
}

.clients-section-two:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 50%;
  width: 100%;
  background: #f5f5f5;
  content: "";
}

.clients-section-two .container {
  max-width: 1400px;
}

.clients-section-two .sponsors-outer {
  position: relative;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 0 30px;
}


.clients-section-two .slide-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 160px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.clients-section-two .slide-item .image-box {
  position: relative;
  text-align: center;
  margin-bottom: 0;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.clients-section-two .slide-item .image-box img {
  width: 100%;
  opacity: 1;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.clients-section-two .slide-item:hover {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .10);
  box-shadow: 0 0 10px rgba(0, 0, 0, .10);
}

.clients-section-two .default-nav .owl-nav {
  margin-top: -15px;
}

.content-slider {
  position: absolute;
  top: 0;
  padding-top: 100px;
  background: #000b468c;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider-title {
  font-size: 50px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
}

.newText {
  color: #c4d627;
  display: block;
}

.nav-toggler {
  background: transparent;
  box-shadow: none;
  outline: 0;
  border: 0;
}

// .image-1 {
//   position: relative;
//   animation-name: example;
//   animation-duration: 5s;
//   animation-timing-function: linear;
//   animation-delay: 5s;
//   animation-iteration-count: infinite;
//   animation-direction: alternate;
// }
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  z-index: 9999;
  background-image: url(../preloader.gif);
  background-size: 250px;
  background-repeat: no-repeat;
  background-position: center;
}

.mapcalender {
  display: flex;
  justify-content: center;
  color: #fff;
  margin-bottom: 12px;
}

.ml-3 {
  margin-left: 1rem;
}

@keyframes example {
  0% {
    left: 0px;
    top: 0px;
  }

  100% {
    left: 200px;
    top: 0px;
  }
}
.desktop__banner{
  display: block !important;
}
.mobile__banner{
  display: none !important;
}
.banner_right_img{
  position: absolute;
  right: 10%;
  top: 20%;
  width: 135px;
  border-radius: 15px;
}
.banner_left_img{
  position: absolute;
  left: 10%;
  top: 20%;
  width: 200px;
  border-radius: 15px;
}
@media only screen and (max-width: 768px) {
  .banner_left_img{
    top: auto;
    bottom: 10%;
    width: 150px;
  }
  .banner_right_img{
    top: auto;
    bottom: 10%;
    width: 100px;
  }
  .time-counter-one .time-countdown .counter-column{
    padding-right: 15px !important;
    margin-right: 15px !important;
    font-size: 14px !important;
  }
  .time-counter-one .time-countdown .counter-column:last-child{
    padding-right: 0px !important;
    margin-right: 0px !important;
  }
  .time-counter-one .time-countdown .counter-column .count{
    font-size: 35px !important;
  }
  .desktop__banner{
    display: none !important;
  }
  .mobile__banner{
    display: block !important;
  }
  .about-section .image-3 {
    left: 0 !important;
  }
  .sec-title h2{
    font-size: 2rem !important;
  }
  .ml-3 {
    margin-left: 0 !important;
  }

  .d-xs-none {
    display: none;
  }

  .header-style-one .header-lower {
    display: none;
  }

  .main-header .main-box:before {
    width: 100% !important;
  }

  .content-slider {
    padding-top: 30px;
    top: 20px;
  }

  .mobile-header {
    display: flex;
  }

  .header-tops {
    display: none;
  }

  .slider-title {
    font-size: 16px;
  }

  .sticky-header .upper-right,
  .mobile-header {
    display: flex !important;
  }
}

/*** 

====================================================================
  Sticky Header
====================================================================

***/

.sticky-header {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0px 0px;
  z-index: 99;
  background: rgba(255, 255, 255, 0.99);
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.fixed-header .sticky-header {
  opacity: 1;
  z-index: 99;
  visibility: visible;
}

.fixed-header .logo-box {
  position: relative !important;
  margin: 0 !important;
  padding: 0 !important;
  min-height: auto !important;
  min-width: auto !important;
  box-shadow: none !important;
  width: 100%;
  justify-content: space-between !important;
}

.fixed-header .logo-box img {
  max-height: 50px;
}

.sticky-header .main-menu .navigation>li {
  margin-right: 0;
  margin-left: 50px;
}

.sticky-header .upper-right {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}

.header-style-three .sticky-header .main-menu:after,
.header-style-three .sticky-header .logo-box:before,
.header-style-three .sticky-header .search-box-btn,
.header-style-three .sticky-header .social-icon-four {
  display: none;
}

.animated {
  animation-duration: 1.25s;
}

/*** 

====================================================================
    Time Counter One
====================================================================

***/

.time-counter-one {
  position: relative;
  display: flex;
  max-width: 100%;
  justify-content: center;
  padding: 20px 30px 30px;
  border-radius: 0 0 30px 0;
  margin-top: 1rem;
  // background: #ff00c6;
  overflow: hidden;
  // background: -o-linear-gradient(45deg, #ff00c6 0%, #610062 100%);
  // background: linear-gradient(45deg, #ff00c6 0%, #610062 100%);
  // margin-left: auto;
}

.time-counter-one:before {
  position: absolute;
  left: -78px;
  top: 0;
  height: 100%;
  width: 100%;
  // background-color: rgba(255, 255, 255, 0.10);
  -webkit-transform: skew(-18deg);
  -ms-transform: skew(-18deg);
  transform: skew(-18deg);
  content: "";
}

.time-counter-one h6 {
  position: relative;
  font-size: 18px;
  color: #c4d627;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
  white-space: nowrap;
}

.time-counter-one .time-countdown .counter-column {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  border-right: 1px solid #ffffff;
  padding-right: 20px;
  margin-right: 20px;
}

.time-counter-one .time-countdown .counter-column:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-right: 0;
}

.time-counter-one .time-countdown .counter-column .count {
  position: relative;
  display: block;
  font-size: 60px;
  line-height: 1em;
  color: #ffffff;
  font-family: 'Exo', sans-serif;
  font-weight: 700;
  margin-right: 5px;
  top: 5px;
}